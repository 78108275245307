<template>
  <div class="jukebox-container">
    <div class="jukebox-player">
      <div class="jukebox-header">
        <div class="jukebox-song-details">
          <div class="jukebox-playlist-name">{{ playlistName }}</div>
          <div class="jukebox-title">
            {{ currentSong.title }}
            <a v-if="currentSong.link" :href="currentSong.link" target="_blank">
              <span class="icon-link" />
            </a>
          </div>
          <div class="jukebox-description">{{ currentSong.description }}</div>
        </div>
        <div class="jukebox-logo-container">
          <a class="jukebox-logo" :href="currentSong.link || 'https://earsketch.gatech.edu/earsketch2'" target="_blank">
            <img src="../assets/img/earsketchlogo.png">
          </a>
          <div class="jukebox-description">Track {{ currentSongIndex + 1 }} of {{ songs.length }}</div>
        </div>
      </div>

      <div class="jukebox-progress-bar">
        <div class="jukebox-progress" :style="{ width: progress + '%' }" />
      </div>
      <div class="jukebox-transport">
        <i class="jukebox-skip-icon icon-backward2" @click="previousSong" />
        <div class="jukebox-play-button" @click="togglePlay">
          <div class="jukebox-play-circle">
            <i :class="isPlaying ? 'icon-pause2' : 'jukebox-play-icon icon-play4'" />
          </div>
        </div>
        <i class="jukebox-skip-icon icon-forward3" @click="nextSong" />
      </div>
    </div>
    <pre><code ref="codeBlock" class="language-py jukebox-code-block">{{ currentSong.code }}</code></pre>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, nextTick, getCurrentInstance } from 'vue'
import hljs from 'highlight.js'

export default {
  name: 'EarSketchJukebox',
  props: {
    songs: {
      type: Array,
      required: true,
    },
    playlistName: {
      type: String,
      required: false,
      default: 'EarSketch',
    },
  },
  setup(props) {
    const instance = getCurrentInstance()
    const audio = ref(null)
    const codeBlock = ref(null)

    const isPlaying = ref(false)
    const progress = ref(0)

    const currentSongIndex = ref(0)
    const currentSong = ref(props.songs[currentSongIndex.value])

    const updateProgress = () => {
      progress.value = (audio.value.currentTime / audio.value.duration) * 100
    }

    const togglePlay = () => {
      if (audio.value.paused) {
        audio.value.play().then(() => {
          isPlaying.value = true
        }).catch((error) => {
          console.error('Error playing audio:', error)
        })
        instance.proxy.$gtag.event('play_jukebox', { song: currentSong.value.title })
      } else {
        audio.value.pause()
        isPlaying.value = false
      }
    }

    const previousSong = () => {
      if (!isPlaying.value) {
        currentSongIndex.value = (currentSongIndex.value - 1 + props.songs.length) % props.songs.length
      }
      isPlaying.value = false
      audio.value.currentTime = 0
      loadSong()
    }

    const nextSong = () => {
      isPlaying.value = false
      currentSongIndex.value = (currentSongIndex.value + 1) % props.songs.length
      loadSong()
    }

    const loadSong = () => {
      currentSong.value = props.songs[currentSongIndex.value]
      audio.value.src = currentSong.value.audio
      audio.value.load()
      progress.value = 0
      if (isPlaying.value) {
        audio.value.play()
      }
      nextTick(() => {
        if (codeBlock.value) {
          hljs.highlightElement(codeBlock.value)
        }
      })
    }

    const handleSongEnd = () => {
      nextSong()
      if (currentSongIndex.value !== 0) {
        togglePlay()
      }
    }

    onMounted(() => {
      audio.value = new Audio(currentSong.value.audio)
      audio.value.addEventListener('timeupdate', updateProgress)
      audio.value.addEventListener('ended', handleSongEnd)
      nextTick(() => {
        if (codeBlock.value) {
          hljs.highlightElement(codeBlock.value)
        }
      })
    })

    onUnmounted(() => {
      audio.value.removeEventListener('timeupdate', updateProgress)
      audio.value.removeEventListener('ended', handleSongEnd)
      audio.value.pause()
    })

    return { togglePlay, isPlaying, progress, previousSong, nextSong, currentSong, currentSongIndex, codeBlock }
  }
}
</script>

<style scoped>
.jukebox-container {
  --container-width: 900px;
  --logo-width: 90px;
  --play-circle-width: 5rem;
  --play-icon-ml: 0.5rem;
  --play-icon-scale: 1;
  --fs-appbar: 2.5rem;
  --fs-title: 1.5rem;
  --fs-playlist-name: 1rem;
  --fs-description: 1rem;
  --fs-play-circle: 3.5rem;
  --fs-code: 1.125rem; /* 18px */
  width: var(--container-width);
  margin: 0 auto;
  background-color: #fafafa;
  border: 4px solid #e6e6e6;
  text-align: left;
  border-radius: 15px;
}
@media (min-width: 950px) {
  .jukebox-container {
    --container-width: min(1200px, 90vw);
    --logo-width: 6rem;
    --play-circle-width: 5rem;
    --play-icon-ml: 0.4rem; /* */
    --play-icon-scale: 1; /* */
    --fs-appbar: min(90px, calc(6px + 12 * (40vw / 100)));
    --fs-title: min(40px, calc(6px + 12 * (25vw / 200)));
    --fs-playlist-name: min(60px, calc(6px + 12 * (25vw / 100)));
    --fs-description: min(32px, calc(6px + 12 * (25vw / 300)));
    --fs-play-circle: 3.5rem;
    --fs-code: min(28px, calc(6px + 12 * (25vw / 300)));
  }
}
@media (min-width: 768px) and (max-width: 949.98px) { /* 0.808421052631579 */
  .jukebox-container {
    --container-width: 700px;
    --logo-width: 4.5rem;
    --play-circle-width: 4rem;
    --play-icon-ml: 0.3rem;
    --play-icon-scale: 1;
    --fs-appbar: calc(6px + 12 * (40vw / 100));
    --fs-title: calc(6px + 12 * (25vw / 200));
    --fs-playlist-name: calc(6px + 12 * (25vw / 100));
    --fs-description: calc(6px + 12 * (25vw / 300));
    --fs-play-circle: 3rem;
    --fs-code: calc(6px + 12 * (25vw / 300));
  }
}
@media (min-width: 576px) and (max-width: 767.98px) { /* 0.606315789473684 */
  .jukebox-container {
    --container-width: 500px;
    --logo-width: 4rem;
    --play-circle-width: 3rem;
    --play-icon-ml: 0.3rem;
    --play-icon-scale: 1;
    --fs-appbar: calc(6px + 12 * (40vw / 100));
    --fs-title: calc(6px + 12 * (25vw / 200));
    --fs-playlist-name: calc(6px + 12 * (25vw / 100));
    --fs-description: calc(6px + 12 * (25vw / 300));
    --fs-play-circle: 2rem;
    --fs-code: calc(6px + 12 * (25vw / 300));
  }
}
@media (max-width: 575.98px) { /* 0.4? */
  .jukebox-container {
    --container-width: 90vw;
    --logo-width: 3.3rem;
    --play-circle-width: 2rem;
    --play-icon-ml: 0.2rem; /* */
    --play-icon-scale: 1; /* */
    --fs-appbar: calc(6px + 12 * (40vw / 100));
    --fs-title: calc(6px + 12 * (25vw / 200));
    --fs-playlist-name: calc(6px + 12 * (25vw / 100));
    --fs-description: calc(6px + 12 * (25vw / 300));
    --fs-play-circle: 1.5rem;
    --fs-code: calc(6px + 12 * (25vw / 300));
  }
}
.jukebox-player {
  display: flex;
  flex-direction: column;
  background: linear-gradient(30deg, purple, orange);
  border-radius: 10px;
  padding: 1rem 1.2rem;
  gap: 1rem;
  font-size: var(--fs-appbar);
  color: black;
}
.jukebox-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.jukebox-song-details {
  display: flex;
  flex-direction: column;
  row-gap: 0.6rem;
  flex: 1;
}
.jukebox-title {
  font-size: var(--fs-title);
  color: white;
}
.jukebox-playlist-name {
  font-size: var(--fs-playlist-name);
  color: #fdba35;
}
.jukebox-logo-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.jukebox-description {
  font-size: var(--fs-description);
  color: whitesmoke;
  min-width: var(--logo-width);
}
.jukebox-logo {
  height: var(--logo-width);
  min-width: var(--logo-width);
}
.jukebox-transport {
  display: flex;
  justify-content: center;
  align-items: center;
}
.jukebox-play-button {
  display: flex;
  justify-content: center;
}
.jukebox-play-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid darkgray;
  border-radius: 50%;
  width: var(--play-circle-width);
  height: var(--play-circle-width);
  color: #3da93d;
  font-size: var(--fs-play-circle);
}
.jukebox-play-circle:hover {
  color: #4b4;
  cursor: pointer;
}
.jukebox-play-icon {
  margin-left: var(--play-icon-ml); /* 0.5rem; */
  scale: var(--play-icon-scale)
}
.jukebox-skip-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 3rem;
  color: #FFFA;
}
.jukebox-skip-icon:hover {
  color: #FFFB;
}
.jukebox-progress-bar {
  height: 7px;
  background-color: #61430840;
  border-radius: 5px;
}
.jukebox-progress {
  height: 100%;
  background-color: #fdba35;
  border-radius: 5px;
}
.jukebox-code-block {
  max-height: 480px;
  border: 0;
  width: 100%;
}
pre {
  display: flex;
  justify-content: center;
}
code.language-py {
  background-color: #fafafa;
  display: inline-block;
  margin: 0 12px;
  padding: 10px;
  line-height: 1.5;
  font-size: var(--fs-code);
  max-width: var(--container-width);
}
</style>
