const tunes = [
{
  title: 'Around the House',
  description: 'Create music with Python code in EarSketch.',
  link: 'https://earsketch.gatech.edu/earsketch2/?sharing=z8UfBPwG6hsgYUgGUpECMw',
  audio: 'https://earsketch.gatech.edu/landing-demos/around-the-house.mp3',
  code: `# Around the House
from earsketch import *
setTempo(130)

fitMedia(HOUSE_ROADS_PIANO_007, 1, 1, 13)
fitMedia(RD_UK_HOUSE_MAINBEAT_24, 2, 1, 12.5)
fitMedia(HOUSE_DEEP_SINELEAD_001, 3, 5, 13)
fitMedia(HOUSE_DEEP_SINELEAD_001, 4, 7.4375, 12.75)
fitMedia(HOUSE_DEEP_SINELEAD_001, 5, 10, 12.25)
`,
},
{
  title: 'Tour de Bubble',
  description: 'Compose drum fills with rhythmic beat strings.',
  link: 'https://earsketch.gatech.edu/earsketch2/?sharing=LzsS-WZiqbeIIScit0Kc3w',
  audio: 'https://earsketch.gatech.edu/landing-demos/tour-de-bubble.mp3',
  code: `# Tour de Bubble
from earsketch import *
setTempo(120)

# Add Sounds
fitMedia(RD_UK_HOUSE_MAINBEAT_8, 1, 1, 5)
fitMedia(RD_POP_SYNTHBASS_6, 2, 1, 9)
fitMedia(YG_RNB_TAMBOURINE_1, 4, 1, 9)
fitMedia(YG_FUNK_CONGAS_3, 5, 1, 5)
fitMedia(YG_FUNK_HIHAT_2, 6, 5, 9)
fitMedia(RD_POP_TB303LEAD_3, 7, 5, 9)

# Effects fade in
setEffect(2, VOLUME,GAIN, -20, 1, 6, 5)

# Fills
fillA = "0---0-0-00--0000"
fillB = "0--0--0--0--0-0-"
fillC = "--000-00-00-0-00"
makeBeat(OS_SNARE03, 3, 4, fillA)
makeBeat(Y09_KICK_1, 3, 8, fillB)
`
},
{
  title: 'Random Solo Funky Adventure',
  description: 'Generate new melodies using randomization.',
  link: 'https://earsketch.gatech.edu/earsketch2/?sharing=49ROIlYIop60w9luMfk5Cg',
  audio: 'https://earsketch.gatech.edu/landing-demos/random-solo-funky-adventure.mp3',
  code: `# Random Solo Funky Adventure
from earsketch import *
setTempo(100)

major = [-2, -7, -5, -3, 0, 2, 3, 5]
minor = [-2, -7, -6, -4, 0, 1, 3, 5]

# Craft our funky rhythms
for start in range(1, 9):
    pBeat = "0" + shuffleString("0012234567+++++")
    sBeat = "".join("0" if c.isdigit() else c for c in pBeat)

    # Slice and shift the guitar
    makeBeat(MILKNSIZZ_BELLARINA_FUZZY_GUITAR, 1, start, sBeat)
    values = [major, minor][(start-1) % 2]
    rhythmEffects(1, PITCHSHIFT, PITCHSHIFT_SHIFT, values, start, pBeat)

# Add backing drums
drums = HIPHOP_DUSTYGROOVE_006
makeBeat(drums, 2, 1, "00-0", 0.5)
insertMediaSection(drums, 2, 5.25, 1.25, 3)

setEffect(1, VOLUME, GAIN, 3)
setEffect(2, VOLUME, GAIN, -6)
`,
},
{
  title: 'Piano Fragments',
  description: 'Slice and rearrange audio samples.',
  link: 'https://earsketch.gatech.edu/earsketch2/?sharing=4TLdEM0Jut4mQwMWHXyTbA',
  audio: 'https://earsketch.gatech.edu/landing-demos/piano-fragments.mp3',
  code: `# Piano Fragments
from earsketch import *
setTempo(120)
setEffect(MIX_TRACK, REVERB, MIX, 0.4)
setEffect(MIX_TRACK, REVERB, REVERB_TIME, 4000)

s = []
for sound in [HOUSE_DEEP_PIANO_002, HOUSE_DEEP_PIANO_003]:
    for start in [1, 1.0625, 1.125, 2, 2.0625, 2.125]:
        s.append(createAudioSlice(HOUSE_DEEP_PIANO_002, start, start+0.25))
        s.append(createAudioSlice(HOUSE_DEEP_PIANO_003, start, start+0.25))

makeBeat(s, 1, 1, shuffleString("0123456789AB----")*3, 4)
makeBeat(s, 2, 1, shuffleString("0123456789AB----")*6, 8)

fitMedia(HIPHOP_DUSTYGROOVE_003, 3, 5, 9)
fitMedia(HIPHOP_DUSTYGROOVE_003, 3, 11, 13)
makeBeat(HIPHOP_DUSTYGROOVE_003, 3, 10, "00000000", 8)
setEffect(3, VOLUME, GAIN, -11)
`
},
{
  title: 'Chips and Salsa',
  description: 'Layer techniques for a spicy blend.',
  link: 'https://earsketch.gatech.edu/earsketch2/?sharing=hmPuQiDg5Smv5xvrCErMfg',
  audio: 'https://earsketch.gatech.edu/landing-demos/chips-and-salsa.mp3',
  code: `# Chips and Salsa
from earsketch import *
setTempo(96)

# Salsa Beat
fitMedia(IRCA_LATIN_JAZZ_1_BASS, 1, 1, 38.1875)
insertMedia(IRCA_OS_TIMBALES_HIGH_FILL, 2, 1.97)

beat = "0000--000000"
makeBeat(IRCA_SALSA_PERC_CONGAS, 2, 3, beat, 0.5)
makeBeat(IRCA_SALSA_PERC_BONGO, 3, 3, beat, 0.5)
makeBeat(IRCA_SALSA_PERC_GUIRO, 4, 3, beat, 0.5)
makeBeat(IRCA_SALSA_PERC_TIMBAL_ABAJO, 5, 3, beat, 0.5)

# Sound Effects
makeBeat(IRCA_OS_TIMBALES_CLAVE, 5, 13, "---0++0+"*4)
fitMedia(RD_UK_HOUSE__SFX_LFOGARBLES_1, 6, 3, 3.25)
fitMedia(RD_FUTURE_DUBSTEP_SFX_2, 6, 7, 7.25)
fitMedia(IRCA_OS_TIMBALES_PLATILLO, 6, 9, 9.25)
fitMedia(DUBSTEP_SFX_004, 6, 11, 12)
setEffect(6, DELAY, DELAY_FEEDBACK, -3)
setEffect(6, DELAY, DELAY_TIME, 310)

# Piano
fitMedia(IRCA_LATIN_JAZZ_1_PIANO_SOLO, 8, 11, 27)
fitMedia(IRCA_LATIN_JAZZ_1_PIANO_3, 9, 19, 38.625)

# Slice those strings
strings = MILKNSIZZ_BUENA_STRINGS
stringsBeat = "0++0--0+--01++++0+++++++2+++++++"
stretch = createAudioStretch(strings, 2)
for start in range(5, 39, 2):
    if start < 11 or start >= 19:
        makeBeatSlice(strings, 7, start, stringsBeat, [1, 1.6875, 4.5])
        insertMediaSection(stretch, 7, start+0.25, 2.675, 2.8)
fitMedia(stretch, 7, 11, 11.5)

# Part 2... reggaeton takeover!
drums = [IRCA_OS_TIMBALES_LOW_CASCARA, IRCA_OS_TIMBALES_HIGH_CASCARA]
makeBeat(drums, 10, 25, "01000-1-01000100"*12)

drums = [IRCA_OS_MARACA_4, IRCA_OS_MARACA_3]
makeBeat(drums, 11, 25, "-0-00-0-10++10++"*12)

drums = [IRCA_OS_KICK, IRCA_OS2_SEGUIDOR_SLAP]
makeBeat(drums, 12, 27, "0++10+1+0++10+1+"*10)

# Set Track Volumes
setEffect(2, VOLUME, GAIN, 3)
setEffect(6, VOLUME, GAIN, -14)
setEffect(7, VOLUME, GAIN, 6)

# Crossfade salsa into reggaeton
setTempo(96, 25, 90, 27)
setEffect(7, VOLUME, GAIN, -60, 19, 6, 23)
setEffect(8, VOLUME, GAIN, 0, 23, -60, 27)
for track in range(3, 6):
    setEffect(track, VOLUME, GAIN, 0, 25, -60, 27)
for track in range(10, 12):
    setEffect(track, VOLUME, GAIN, -60, 25, 0, 27)

# Outro Filter
setEffect(MIX_TRACK, FILTER, FILTER_FREQ, 20000, 34.5, 1500, 35)
`,
},
{
  title: 'Lofi Chill',
  description: 'Take rhymic subdivisions to the limit.',
  link: 'https://earsketch.gatech.edu/earsketch2/?sharing=_VV8uoId8rqZ-_VrpIYjlA',
  audio: 'https://earsketch.gatech.edu/landing-demos/lofi-chill.mp3',
  code: `# Lofi Chill
from earsketch import *
setTempo(80)

# Divide beat into 5 equal parts
kick = AK_UNDOG_PERC_KICK_2
snare = createAudioSlice(MILKNSIZZ_AFROSENSE_BACK_SNARE , 1.375, 1.5)
hat = MILKNSIZZ_OCEANS_LOFI_HAT
for start in range(1, 9):
    # Kick on 1 and 4 quintuplet
    makeBeat(kick, 1, start, "0--0-"*4, 20)
    makeBeat(snare, 2, start, "-0-0", 4)
    # Hihat quintuplet feel
    makeBeat(hat, 3, start, "00000"*4, 20)
    rhythmEffects(3, VOLUME, GAIN, [-15, -17, -22], start, "01121"*4, 20)

setEffect(2, REVERB, REVERB_TIME, 4000)
setEffect(2, REVERB, REVERB_DAMPFREQ, 5000)
setEffect(2, VOLUME, GAIN, -3)

# Add dreamy chord progression
gtr = MILKNSIZZ_COFFEE_DRIP_WAVY_GUITAR
gtrSlices = [1, 1.5, 2, 2.5]
gtrBeat1 = "0+++----0+++3221"
gtrBeat2 = "3+++++++3+3+---3++++2+++++++++1+++++++++"
gtrPitches = [0, 1, -1]
gtrFx = "0++20+1-0++++++++20+2+1-0"

makeBeatSlice(gtr, 4, 1, gtrBeat1, gtrSlices, 2)
makeBeatSlice(gtr, 4, 3, gtrBeat2, gtrSlices, 20)
rhythmEffects(4, PITCHSHIFT, PITCHSHIFT_SHIFT, gtrPitches, 3, gtrFx, 4)
`,
},
{
  title: 'Time\'s Up',
  description: 'Use functions to compose at a higher time scale.',
  link: 'https://earsketch.gatech.edu/earsketch2/?sharing=yoo6DGin1vwmTQ7uDBz5-g',
  audio: 'https://earsketch.gatech.edu/landing-demos/times-up.mp3',
  code: `# Time's Up
from earsketch import *
from random import randint
setTempo(120)

def addSection(clips, drums, beat, start, end, leadSegDur=0):
    track = 3
    for clip in clips:
        fitMedia(clip, track, start, end)
        track += 1
    makeBeat(drums, 1, start, beat)
    if leadSegDur:
        generateLead(leadSegDur, start, end)

def generateLead(leadSegDur, start, end):
    leadClips = [RD_EDM_RAVELEAD_1, RD_EDM_RAVELEAD_2, RD_EDM_RAVELEAD_3,
                 RD_EDM_RAVELEAD_4, RD_EDM_RAVELEAD_5, RD_EDM_RAVELEAD_6]
    while start < end:
        fitMedia(leadClips[randint(0, 5)], 2, start, start+leadSegDur)
        start += leadSegDur

clips1 = [RD_EDM_CHORDPART_5, RD_EDM_PERCSYNTHLEAD_1, YG_EDM_FX_12]
clips2 = clips1 + [RD_EDM_SFX_RISER_1]
clips3 = [RD_EDM_RAZORBASS_2, RD_EDM_ANALOGLEAD_4, RD_EDM_ANALOGPLUCK_2]

drums = [OS_KICK02, OS_SNARE04, Y24_ELECTRO_2, OS_OPENHAT05]
beat1 = "0+++0+++0+++0+++"*4
beat2 = "0+1+0+110+1+0111"*4
beat3 = "03202---1--12--2"*4

addSection(clips1, drums, beat1, 1, 5)
addSection(clips2, drums, beat2, 5, 9, 2)
addSection(clips3, drums, beat3, 9, 13, 0.5)
addSection(clips3, drums, beat3, 13, 17, 0.25)
addSection(clips1, drums, beat1, 17, 21, 1)
addSection(clips1, drums, "", 21, 25)

# Effects
pan = [0, 0, 50, -50, -35, 35]
vol = [0, -1, 0, 0, 0, 0]
for track in range(1, 7):
    setEffect(track, PAN, LEFT_RIGHT, pan[track-1])
    setEffect(track, VOLUME, GAIN, vol[track-1])
`
},
]

export default tunes
